// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
//require("jquery-ui")
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//document.documentElement.requestFullscreen();

import "@fortawesome/fontawesome-free/js/all";

document.addEventListener("turbolinks:load", function() {

  // $('.sortable').sortable({
  //   axis: 'y',
  //   items: '.item',
  //   handle: '.handle',
  //   update: function(e, ui) {
  //     sort_update_url = ui.item.data('sort-update-url');
  //     position = ui.item.index();
  //     $.ajax({
  //       type: 'PUT',
  //       url: sort_update_url,
  //       data: { position: position+1 }
  //     }).done(function(html) {
  //       // Currently assumes sortable objects are held within a modal dialog
  //       //modal = $(ui.item).closest('.modal');
  //       //Modal.load(html, modal);
  //     });
  //   }
  // }).disableSelection();

//   var resultContainer = document.getElementById('qr-reader-results');
//   var lastResult, countResults = 0;

//   function onScanSuccess(qrCodeMessage) {
//       if (qrCodeMessage !== lastResult) {
//           ++countResults;
//           lastResult = qrCodeMessage;
//           resultContainer.innerHTML 
//               += `<div>[${countResults}] - ${qrCodeMessage}</div>`;
//       }
//   }

//   var html5QrcodeScanner = new Html5QrcodeScanner(
//       "qr-reader", { fps: 10, qrbox: 250 });
//   html5QrcodeScanner.render(onScanSuccess);
});